import get_operation_guide_title from '@/lib/data-service/yinzhilv-temporary-storage-system/get_operation_guide_title'
import get_operation_guide from '@/lib/data-service/yinzhilv-temporary-storage-system/get_operation_guide'
export default {
    data () {
        return {
            loading: false,
            titleList: [],
            activeIndex: 0,
            content: '',
            leftActiveBarStyle: 'transform: translateY(0px)'
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
        this.getOpaGuideTitleList()
        this.handleActive()
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        handleMouseOver(val) {
            let item = this.$refs[`menuItem${val}`][0]
            let top = item.offsetTop
            console.log(top)
            this.leftActiveBarStyle = `transform: translateY(${top}px)`
        },
        handleActive(val) {
            if (this.titleList.length === 0) {
                return
            }
            let valNum = 0
            if (val) {
                valNum = val
            } else {
                valNum = this.activeIndex
            }
            this.$nextTick(() => {
                let item = this.$refs[`menuItem${valNum}`][0]
                let top = item.offsetTop
                this.leftActiveBarStyle = `transform: translateY(${top}px)`
            })
        },
        getOpaGuideTitleList() {
            get_operation_guide_title().then(res => {
                console.log(res)
                if (res.data && res.data.allQuanchengOperationGuideLists) {
                    this.titleList = res.data.allQuanchengOperationGuideLists
                    let firstId = this.titleList[0].id
                    this.getContent(firstId, 0)
                }
            })
        },
        getContent(id, index) {
            this.activeIndex = index
            get_operation_guide({id}).then(res => {
                console.log(res)
                if (res.data && res.data.QuanchengOperationGuideList) {
                    this.content = res.data.QuanchengOperationGuideList.content
                }
            })
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/