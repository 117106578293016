// get_operation_guide 根据id获取指南内容
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_operation_guide(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {
        id: ''
    };
    let query = {
        query: `query {
            QuanchengOperationGuideList(where: {id: "${pParameter.id}"}) {
                id,
                title,
                content
             }
        }`
    }
    params.method = 'POST'
    params.urlSuffix = '/admin/api'
    params.data = query
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}