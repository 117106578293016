// get_operation_guide_title 获取指南标题
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_operation_guide_title(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    let query = {
        query: `query {
            allQuanchengOperationGuideLists(sortBy: sortIndex_ASC) {
                id,
                sortIndex,
                title,
                iconName
             }
        }`
    }
    params.method = 'POST'
    params.urlSuffix = '/admin/api'
    params.data = query
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}